body.inverted {

  .content {
  
    .list {
      ul {
        li {
          .title {
            color: $fg-color-inverted;
            &:hover,
            &:focus {
              color: $link-color-inverted
            }
          }
        }
      }
    }

    .centered {
      .about {
        ul {
          li {
            a {
              color: $fg-color-inverted;
              &:hover,
              &:focus {
                color: $link-color-inverted;
              }
            }
          }
        }
      }
    }
  }

}
