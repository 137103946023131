body.inverted {

  color: $fg-color-inverted;
  background-color: $bg-color-inverted;
  
  a {
    color: $link-color-inverted;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $alt-fg-color-inverted;
  }

  code {
    background-color: $alt-bg-color-inverted;
    color: $fg-color-inverted;
  }

  pre {
    code {
      background-color: inherit;
      color: inherit;
    }
  }

  blockquote {
    border-left: 2px solid $alt-bg-color-inverted;
  }

  table td, table th {
    border: 2px solid $alt-fg-color-inverted;
  }

}
